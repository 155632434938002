var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"replies"},[_c('view-reply-modal',{attrs:{"view-reply-modal":_vm.viewReplyModal,"reply":_vm.selectedReply || {}},on:{"closeViewReplyModal":_vm.closeViewReplyModal}}),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"flat":""}},[_c('v-tabs',{attrs:{"centered":"","fixed-tabs":""},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tab',{attrs:{"href":"#tab-new"}},[_vm._v(" New Replies ")]),_c('v-tab',{attrs:{"href":"#tab-archived"}},[_vm._v(" Archived Replies ")])],1),_c('v-tabs-items',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tab-item',{attrs:{"value":"tab-new"}},[_c('v-card-text',[_c('v-container',[_vm._l((_vm.newReplies),function(reply){return [_c('div',{key:reply._id},[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-message-reply-text-outline ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('a',{attrs:{"href":""},on:{"click":function($event){return _vm.openViewReplyModal($event, reply)}}},[_vm._v(_vm._s(reply.fullName))])]),_c('v-list-item-subtitle',{staticClass:"text--primary",domProps:{"textContent":_vm._s(reply.subjectLine)}}),_c('v-list-item-subtitle',{staticClass:"text--small"},[_vm._v(_vm._s(reply.textContent))])],1),_c('v-list-item-action',[_c('v-list-item-action-text',[(!reply.handled)?_c('v-icon',{attrs:{"color":"primary","x-large":""}},[_vm._v(" mdi-circle-small ")]):_vm._e()],1),_c('span',[_c('a',{staticClass:"noline",attrs:{"href":reply.isOpenSRS
                                ? ("https://mail.hostedemail.com/mail?user=" + (reply.senderEmail) + "&pass=" + (reply.senderPassword))
                                : 'https://webmail.loopia.se',"target":"_blank"}},[_c('v-icon',{attrs:{"color":"purple darken-2"}},[_vm._v(" mdi-reply ")])],1),_vm._v("   "),_c('v-icon',{attrs:{"color":"green darken-2"},on:{"click":function($event){return _vm.lead(reply)}}},[_vm._v(" mdi-account-cash-outline ")]),_vm._v("   "),_c('v-icon',{attrs:{"color":"red darken-2"},on:{"click":function($event){return _vm.nointerest(reply)}}},[_vm._v(" mdi-account-cancel-outline ")]),_vm._v("   "),_c('router-link',{staticClass:"noline",attrs:{"to":{
                              name: 'prospect',
                              params: { prospectId: reply.prospectId },
                            }}},[_c('v-icon',{attrs:{"color":"grey darken-2"}},[_vm._v(" mdi-account-eye-outline ")])],1),_vm._v("   "),_c('v-icon',{attrs:{"color":"primary darken-2"},on:{"click":function($event){return _vm.archive(reply)}}},[_vm._v(" mdi-email-check-outline ")])],1)],1)],1),_c('v-divider',{attrs:{"inset":""}})],1)]})],2)],1)],1),_c('v-tab-item',{attrs:{"value":"tab-archived"}},[_c('v-card-text',[_c('v-container',[_vm._l((_vm.archivedReplies),function(reply){return [_c('div',{key:reply._id},[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-message-reply-text-outline ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('a',{attrs:{"href":""},on:{"click":function($event){return _vm.openViewReplyModal($event, reply)}}},[_vm._v(_vm._s(reply.fullName))])]),_c('v-list-item-subtitle',{staticClass:"text--primary",domProps:{"textContent":_vm._s(reply.subjectLine)}}),_c('v-list-item-subtitle',{staticClass:"text--small"},[_vm._v(_vm._s(reply.textContent))])],1),_c('v-list-item-action',[_c('span',[_c('a',{staticClass:"noline",attrs:{"href":reply.isOpenSRS
                                ? ("https://mail.hostedemail.com/mail?user=" + (reply.senderEmail) + "&pass=" + (reply.senderPassword))
                                : 'https://webmail.loopia.se',"target":"_blank"}},[_c('v-icon',{attrs:{"color":"purple darken-2"}},[_vm._v(" mdi-reply ")])],1),_vm._v("   "),_c('v-icon',{attrs:{"color":"green darken-2"},on:{"click":function($event){return _vm.lead(reply)}}},[_vm._v(" mdi-account-cash-outline ")]),_vm._v("   "),_c('v-icon',{attrs:{"color":"red darken-2"},on:{"click":function($event){return _vm.nointerest(reply)}}},[_vm._v(" mdi-account-cancel-outline ")]),_vm._v("   "),_c('router-link',{staticClass:"noline",attrs:{"to":{
                              name: 'prospect',
                              params: { prospectId: reply.prospectId },
                            }}},[_c('v-icon',{attrs:{"color":"grey darken-2"}},[_vm._v(" mdi-account-eye-outline ")])],1)],1)])],1),_c('v-divider',{attrs:{"inset":""}})],1)]})],2)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }