<template>
  <div class="replies">
    <view-reply-modal
      :view-reply-modal="viewReplyModal"
      :reply="selectedReply || {}"
      @closeViewReplyModal="closeViewReplyModal"
    ></view-reply-modal>
    <v-row>
      <v-col>
        <v-card flat>
          <v-tabs v-model="selectedTab" centered fixed-tabs>
            <v-tab :href="`#tab-new`"> New Replies </v-tab>
            <v-tab :href="`#tab-archived`"> Archived Replies </v-tab>
          </v-tabs>
          <v-tabs-items v-model="selectedTab">
            <v-tab-item value="tab-new">
              <v-card-text>
                <v-container>
                  <template v-for="reply in newReplies">
                    <div v-bind:key="reply._id">
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon> mdi-message-reply-text-outline </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title
                            ><a
                              href
                              @click="openViewReplyModal($event, reply)"
                              >{{ reply.fullName }}</a
                            ></v-list-item-title
                          >
                          <v-list-item-subtitle
                            class="text--primary"
                            v-text="reply.subjectLine"
                          ></v-list-item-subtitle>
                          <v-list-item-subtitle class="text--small">{{
                            reply.textContent
                          }}</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-list-item-action-text>
                            <v-icon
                              v-if="!reply.handled"
                              color="primary"
                              x-large
                            >
                              mdi-circle-small
                            </v-icon>
                          </v-list-item-action-text>
                          <span>
                            <a
                              :href="
                                reply.isOpenSRS
                                  ? `https://mail.hostedemail.com/mail?user=${reply.senderEmail}&pass=${reply.senderPassword}`
                                  : 'https://webmail.loopia.se'
                              "
                              target="_blank"
                              class="noline"
                              ><v-icon color="purple darken-2">
                                mdi-reply
                              </v-icon>
                            </a>
                            &nbsp;
                            <v-icon color="green darken-2" @click="lead(reply)">
                              mdi-account-cash-outline
                            </v-icon>
                            &nbsp;
                            <v-icon
                              color="red darken-2"
                              @click="nointerest(reply)"
                            >
                              mdi-account-cancel-outline
                            </v-icon>
                            &nbsp;
                            <router-link
                              class="noline"
                              :to="{
                                name: 'prospect',
                                params: { prospectId: reply.prospectId },
                              }"
                              ><v-icon color="grey darken-2">
                                mdi-account-eye-outline
                              </v-icon>
                            </router-link>
                            &nbsp;
                            <v-icon
                              color="primary darken-2"
                              @click="archive(reply)"
                            >
                              mdi-email-check-outline
                            </v-icon>
                          </span>
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider inset></v-divider>
                    </div>
                  </template>
                </v-container>
              </v-card-text>
            </v-tab-item>
            <v-tab-item value="tab-archived">
              <v-card-text>
                <v-container>
                  <template v-for="reply in archivedReplies">
                    <div v-bind:key="reply._id">
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon> mdi-message-reply-text-outline </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title
                            ><a
                              href
                              @click="openViewReplyModal($event, reply)"
                              >{{ reply.fullName }}</a
                            ></v-list-item-title
                          >
                          <v-list-item-subtitle
                            class="text--primary"
                            v-text="reply.subjectLine"
                          ></v-list-item-subtitle>
                          <v-list-item-subtitle class="text--small">{{
                            reply.textContent
                          }}</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                          <span>
                            <a
                              :href="
                                reply.isOpenSRS
                                  ? `https://mail.hostedemail.com/mail?user=${reply.senderEmail}&pass=${reply.senderPassword}`
                                  : 'https://webmail.loopia.se'
                              "
                              target="_blank"
                              class="noline"
                              ><v-icon color="purple darken-2">
                                mdi-reply
                              </v-icon>
                            </a>
                            &nbsp;
                            <v-icon color="green darken-2" @click="lead(reply)">
                              mdi-account-cash-outline
                            </v-icon>
                            &nbsp;
                            <v-icon
                              color="red darken-2"
                              @click="nointerest(reply)"
                            >
                              mdi-account-cancel-outline
                            </v-icon>
                            &nbsp;
                            <router-link
                              class="noline"
                              :to="{
                                name: 'prospect',
                                params: { prospectId: reply.prospectId },
                              }"
                              ><v-icon color="grey darken-2">
                                mdi-account-eye-outline
                              </v-icon>
                            </router-link>
                          </span>
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider inset></v-divider>
                    </div>
                  </template>
                </v-container>
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import { store } from "@/state/store.js";
import ViewReplyModal from "@/components/ViewReplyModal.vue";
export default {
  components: {
    ViewReplyModal,
  },
  data() {
    return {
      replies: [],
      selectedReply: {},
      viewReplyModal: false,
      store,
      selectedTab: "tab-new",
    };
  },
  created() {
    this.loadReplies();
  },
  computed: {
    selectedClient() {
      return this.store.selectedClient;
    },
    newReplies() {
      return this.replies.filter((r) => r.handled === false);
    },
    archivedReplies() {
      return this.replies.filter((r) => r.handled === true);
    },
  },
  methods: {
    formatDate(dt) {
      if (!dt) {
        return "";
      }
      return moment(dt).format("MM/DD/YYYY");
    },
    async loadReplies() {
      try {
        const repliesResp = await this.$http.get(
          `/replies/${this.selectedClient}`
        );
        this.replies = repliesResp.data;
      } catch (error) {
        console.log(error);
      }
    },
    openViewReplyModal(e, reply) {
      e.preventDefault();
      this.selectedReply = reply;
      this.viewReplyModal = true;
    },
    closeViewReplyModal() {
      this.viewReplyModal = false;
    },
    async archive(reply) {
      try {
        await this.$http.post(`/replies/${reply._id}/archive`);
        this.loadReplies();
      } catch (error) {
        console.log(error);
      }
    },
    async nointerest(reply) {
      try {
        await this.$http.post(`/prospects/${reply.prospectId}/status`, {
          newStatus: "NOINTEREST",
        });
        await this.$http.post(`/replies/${reply._id}/archive`);
        this.loadReplies();
      } catch (error) {
        console.log(error);
      }
    },
    async lead(reply) {
      try {
        await this.$http.post(`/replies/${reply._id}/lead`);
        await this.$http.post(`/prospects/${reply.prospectId}/status`, {
          newStatus: "LEAD",
        });
        await this.$http.post(`/replies/${reply._id}/archive`);
        this.loadReplies();
      } catch (error) {
        console.log(error);
      }
    },
  },
  watch: {
    selectedClient() {
      this.loadReplies();
    },
  },
};
</script>

<style lang="scss" scoped>
.text--small {
  font-size: 12px;
}
.noline {
  text-decoration: none;
}
</style>
