<template>
  <v-dialog
    v-model="viewReplyModal"
    max-width="650px"
    @click:outside="closeViewReplyModal()"
  >
    <v-card>
      <v-card-title>
        <span class="headline"
          >Reply from {{ reply.fullName }} on
          {{ formatDate(reply.createdAt) }}</span
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" class="mx-2" lazy-validation>
            <v-row>
              <v-col cols="12">
                <h4>{{ reply.subjectLine }}</h4>
              </v-col>
              <v-col cols="12">
                <div v-html="reply.htmlContent"></div>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeViewReplyModal()"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {};
  },
  props: {
    reply: Object,
    viewReplyModal: Boolean,
  },
  methods: {
    formatDate(d) {
      return moment(d).format("YYYY-MM-DD HH:mm:ss");
    },
    closeViewReplyModal() {
      this.$emit("closeViewReplyModal");
    },
  },
};
</script>
